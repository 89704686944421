import Allianz from "../../assets/convenios/Allianz.svg"
import AmapEmbratel from "../../assets/convenios/AMAPEmbratel.svg"
import AmapTelos from "../../assets/convenios/AMAPTelos.svg"
import Assefaz from "../../assets/convenios/ASSEFAZ.svg"
import Caixa from "../../assets/convenios/CAIXA.svg"
import Cassi from "../../assets/convenios/CASSI.svg"
import Gama from "../../assets/convenios/GAMA.svg"
import Inb from "../../assets/convenios/INB.svg"
import Ipalerj from "../../assets/convenios/IPALERJ.svg"
import NotreDame from "../../assets/convenios/NotreDame.svg"
import PlamCnen from "../../assets/convenios/PLAM.svg"
import PostalSaude from "../../assets/convenios/PostalSaude.svg"
import RealGrandeza from "../../assets/convenios/RealGrandeza.svg"
import Serpro from "../../assets/convenios/Serpro.svg"
import Vale from "../../assets/convenios/Vale.svg"
import capesesp from "../../assets/convenios/CAPESESP.svg"
import BradescoSaude from "../../assets/convenios/BradescoSaude.svg"
import BradescoSaudeOperadora from "../../assets/convenios/BradescoOperadora.svg"
import Mediservice from "../../assets/convenios/Mediservice.svg"
import IndustriasGranfino from "../../assets/convenios/INDUSTRIAS GRANFINO.svg"
import FioSaude from "../../assets/convenios/FioSaude.png"

const getConvenioLogo = which => {
  let convenio = {}
  convenio["convenio-id-9"] = Allianz
  convenio["convenio-id-2"] = AmapEmbratel
  convenio["convenio-id-840"] = AmapTelos
  convenio["convenio-id-6"] = Assefaz
  convenio["convenio-id-10"] = Caixa
  convenio["convenio-id-1378"] = Cassi
  convenio["convenio-id-14"] = Gama
  convenio["convenio-id-11"] = Inb
  convenio["convenio-id-2445"] = IndustriasGranfino
  convenio["convenio-id-836"] = Ipalerj
  convenio["convenio-id-5"] = NotreDame
  convenio["convenio-id-1094"] = PlamCnen
  convenio["convenio-id-8"] = PostalSaude
  convenio["convenio-id-448"] = RealGrandeza
  convenio["convenio-id-1421"] = Serpro
  convenio["convenio-id-16"] = Vale
  convenio["convenio-id-13"] = capesesp
  convenio["convenio-id-2463"] = BradescoSaude
  convenio["convenio-id-2467"] = BradescoSaudeOperadora
  convenio["convenio-id-2466"] = Mediservice
  convenio["convenio-id-2580"] = FioSaude

  if (typeof convenio[`convenio-id-${which}`] !== "undefined") {
    return convenio[`convenio-id-${which}`]
  } else {
    return false
  }
}

export default getConvenioLogo
